.products_card_image {
    max-width: 100%;
}
.products_card_info_container {
    background-color: #FBFBFB;
    padding: 30px;
    margin-bottom: 61px;
    width: 100%;
}
.products_card_main_title > h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 19px;
    color: #262555;
}
.products_card_info_header {
    margin-bottom: 4px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
}
.products_card_info_sku {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #D39340;
    display: flex;
    flex-flow: column;
}
.products_card_rates_container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}
.fas.fa-star {
    color: #FF8205;
    font-size: 14px;
    margin-right: 5px;
}
.fas.fa-star-half {
    color: #FF8205;
    font-size: 14px;
    margin-right: 5px;
}
.products_card_rates_container > span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #7E848E;
    margin-left: 6px;
}
.products_card_image_container {
    background-color: #FFFFFF;
    overflow: hidden;
    opacity: 1;
}
.products_card_general_info {
    margin-top: 50px;
}
.products_card_in_stock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #262555;
    margin-bottom: 25px;
}
.products_card_in_stock > img {
    margin-right: 10px;
}
.products_card_datas_container {
    padding-left: 70px;
}
.products_card_options_title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #262555;
    margin-bottom: 15px;
}
.products_cart_options_item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #262555;
    margin-bottom: 15px;
}
.products_cart_options_item_title {
    min-width: 150px;
    max-width: 150px;
    margin-right: 7px;
}
.products_cart_options_item_value {
    font-weight: 300;
}
.products_cart_options_items {
    border-bottom: 1px solid rgba(38, 37, 85, 0.3);
    margin-bottom: 21px;
}
.products_card_price_container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}
.products_card_buttons_container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
}
.products_card_price_all {
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 24px;
    color: #262555;
    margin-bottom: 5px;
}
.products_cart_price_discount .product_gallery_discount_price_container .product_gallery_price_discount{
    margin-top: 20px;
    padding-left: 4%;
}
.products_cart_price_discount .product_gallery_discount_price_container {
    flex-flow: column;
}
.products_card_price_for_one {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: rgba(38, 37, 85, 0.5);
}
.products_card_buttons_container .btn {
    width: 178px;
    height: 37px;
    background: #D39340;
    box-shadow: 0 4px 4px rgba(38, 37, 85, 0.1);
    border-radius: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #262555;
}
.products_card_buttons_container .btn:not(:last-child) {
    margin-bottom: 21px;
}
.products_card_buttons_container {
    margin-left: 25px;
}
.products_card_buttons_container .btn.btn_inverse {
    background: #262555;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}
.products_card_tabs_container .nav-link {
    width: 197px;
    height: 51px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    color: rgba(38, 37, 85, 0.5);
}
.products_card_tabs_container .nav-link.active {
    border-top: 5px solid #D39340;
    background: #FFFFFF;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 24px;
    color: #262555;
}
.products_card_tabs_container .tab-content {
    padding: 35px 40px;
    background: #FFFFFF;
}
.nav-tabs {
    border-bottom: none;
}
.products_card_tabs_container .tab-pane {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}
.products_card_tabs_container .tab-pane b {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 24px;
    color: #262555;
    padding-bottom: 13px;
}
.products_card_tabs_container {
    /*margin-bottom: 95px;*/
    width: 100%;
    height: auto;
}
.tags_item a {
    color: black!important;
}
.pdf_link_icon {
    font-size: 30px;
    display: inline-block;
    margin-right: 9px;
    cursor: pointer!important;
}
.documents_for_product_list {
    display: flex;
    flex-flow: column;
}
.documents_for_product_list a {
    color: #D39340!important;
}
.documents_for_product_list a:hover {
    color: #262555!important;
}
.product_detail_info_price_change {
    /*text-align: right;*/
    font-size: 12px;
    color: grey;
    width: 100%;
}
.product_card_long_name {
    color: grey;
    font-weight: 600;
}
.products_card_info_sku span {
    color: grey;
}
.products_card_image_container .css-olgc7d {
    display: flex;
    align-items: center;
    text-align: center;
}
.products_card_image_container .css-olgc7d img {
    height: auto;
}
@media (max-width: 767px) {
    .products_card_datas_container {
        padding-left: 0;
        margin-top: 20px;
    }
    .products_card_info_header {
        flex-flow: column nowrap;
    }
}